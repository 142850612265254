<template>
  <div class="page-box">
    <header>
      <a-clock />
      <a-button type="link" @click="refresh">刷新</a-button>
    </header>
    <a-spin :spinning="loading">
      <a-collapse :bordered="false" :defaultActiveKey="['0', '1']">
        <a-collapse-panel key="0" header="账户概览">
          <a-card>
            <template #title>
              <a-statistic :value="data.notSettleAmount" suffix="元">
                <template #title>
                  <p class="title">未结算总金额</p>
                  <p class="sub-title">下单成功，新零售财务未打款金额</p>
                </template>
              </a-statistic>
            </template>

            <div style="display: flex">
              <a-statistic :value="data.waitSettleAmount" suffix="元">
                <template #title>
                  <p class="title">
                    待结算总金额
                    <router-link class="link" to="/capital/settle/wait">订单明细</router-link>
                  </p>
                  <p class="sub-title">下单成功，未到打款时间的金额</p>
                </template>
              </a-statistic>
              <a-statistic style="margin-left: 150px;" :value="data.canSettleAmount" suffix="元">
                <template #title>
                  <p class="title">
                    可结算总金额
                    <router-link class="link" to="/capital/settle/can">订单明细</router-link>
                  </p>
                  <p class="sub-title">交易完成，新零售财务可打款金额</p>
                </template>
              </a-statistic>
            </div>
          </a-card>
        </a-collapse-panel>

        <a-collapse-panel key="1" header="收款历史概况">
          <a-card>
            <template #title>
              <div style="display: flex;">
                <a-statistic :value="data.totalFinishSettleAmount" suffix="元">
                  <template #title>
                    <p class="title">
                      合计已结算金额
                      <router-link class="link" to="/capital/settle/already">订单明细</router-link>
                    </p>
                  </template>
                </a-statistic>

                <a-statistic style="margin-left: 150px" :value="data.finishSettleGoodAmount" suffix="元">
                  <template #title>
                    <p class="title">
                      已结算货款
                    </p>
                  </template>
                </a-statistic>

                <a-statistic style="margin-left: 150px" :value="data.finishSettleCommissionAmount" suffix="元">
                  <template #title>
                    <p class="title">
                      已结算佣金
                    </p>
                  </template>
                </a-statistic>
              </div>
            </template>
          </a-card>
        </a-collapse-panel>
      </a-collapse>
    </a-spin>
  </div>
</template>

<script>
import AClock from '@/components/AClock'
import { capital } from '@/api'
import { onMounted, ref } from '@vue/composition-api'
export default {
  name: 'PageSettleDashboard',
  components: {
    AClock,
  },
  setup (props) {
    const data = ref({
      notSettleAmount: 0,
      waitSettleAmount: 0,
      canSettleAmount: 0,
      totalFinishSettleAmount: 0,
      finishSettleGoodAmount: 0,
      finishSettleCommissionAmount: 0,
    })
    const loading = ref(false)
    async function getData () {
      loading.value = true
      const res = await capital.getSettleInfo()
      loading.value = false
      data.value = res.data
    }
    onMounted(getData)

    return {
      data,
      loading,
      refresh: getData,
    }
  }
}
</script>

<style lang="less" scoped>
.link.link.link {
  color: @main-color;
  margin-left: 10px;
}
.title {
  color: #666;
  font-size: 14px;
}
.sub-title {
  color: #999;
  font-size: 12px;
}
</style>
